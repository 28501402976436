@import '@styles/mixins.scss';

.bookingContainer {
    position: absolute;
    bottom: 100px;
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: center;

    .bookingWrapper {
        background-color: $brown;
        padding: 40px 12px 24px 12px;
        width: 100%;
        max-width: 1200px;
        border-radius: 5px;
        margin: 0 auto;
        display: flex;
    }
    
    .fieldWrapper {
        margin: 0 12px;
        position: relative;
        min-width: 200px;
        &RoomGuests{
            min-width: 300px;
        }
        &Active{
            border-color: $orange;
        }
        .label {
            color: $white;
            font-size: $size_14;
            position: absolute;
            top: -24px;
            font-weight: 100;
        }
        .field {
            display: flex;
            align-items: center;
            justify-content: space-between;
            
            .separator {
                margin: 0 10px;
            }
        }
        .toggle {
            font-size: $size_24;
        }
        &:last-child {
            min-width: auto;
            flex: 1;
        }
    }
    .inputWrapper {
        display: flex;
        min-width: 342px;

        .fieldGroup {
            display: contents;
        }

        .field {
           width: 100%; 
        }
    }
    .selector {
        &Open{ 
            visibility: visible;
            opacity: 1;
        }
        &Close{ 
            visibility: hidden;
            opacity: 0;
        }
        
        position: absolute;
        z-index: 9;
        width: 100%;
        background: #fff;
        border-radius: 3px;
        top: calc(100% + 6px);
        padding: 20px 15px;
        box-shadow: 0 2px 6px rgb(0 0 0 / 5%), 0 0 0 1px rgb(0 0 0 / 7%);
    
        .wrapper {
            .qtySelector {
                display: flex;
                justify-content: space-between;
                margin-bottom: 5px;
                .title {
                    font-weight: 100;
                    font-size: $size_14;
                }
                .qty {
                    button {
                        margin: 0;
                        padding: 0;
                        border: 0;
                        background: transparent;
                        cursor: pointer;
                    }
                    button:disabled {
                        opacity: .3;
                    }
                    span {
                        display: inline-block;
                        width: 10px;
                        text-align: center;
                        margin: 0 5px;
                    }
                }
            }
            .roomTitle {
                font-family: $font-fertigo;
                font-size: $size_12;
                line-height: $size_20;
                margin: 10px 0;
                letter-spacing: 2px;
                color: $khaki;
                text-transform: uppercase;
                border-bottom: 2px solid $khaki;
            }
        }
    }
    .boxSize {
        min-height: 24px;
    }
}
.fieldSeparator {
    width: 25px;
}

.showDesktop {
    @include desktop-screen-dynamic(1216px) {
        display: flex;
    }
    @include mobile-screen-dynamic(1216px) {
        display: none !important;
    }
}

.showMobile {
    @include desktop-screen-dynamic(1216px) {
        display: none;
    }
    @include mobile-screen-dynamic(1216px) {
        display: flex;
        max-width: 523px;
        align-items: center;
        justify-content: center;
    }
}

.buttonMargin {
    margin: 0 4em;
}

.promoText {
    font-size: $size_12;
    font-family: fertigo-pro, serif;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 2px;
    cursor: pointer;
    color: $orange;

    &:hover {
        color: $orange-hover;
    }
}

.bookingPopupBackground {
    background-color: rgba(0,0,0,0.5);
    width: 100vw;
    height: calc(100vh - 50px);
    position: fixed;
    padding: 2em;
    display: flex;
    justify-content: center;
    z-index: 10;
    //overflow-y: scroll;

    .closeContainer {
        text-align: end;
        margin-top: -6px;
        margin-right: -10px;
        margin-bottom: -0.7em;

        .closeButton {
            background-color: $white;
            border: none;
        }
    }

    .close {
        color: $orange;
        font-size: 10px;
        background-color: Transparent;
        border: 0;
        padding: 0;
        cursor: pointer;
        
        &:hover {
            color: $orange-hover;
        }
    }

    .bookingPopup {
        background-color: $white;
        width: 100%;
        max-width: 340px;
        border-radius: 10px;
        padding: 1em;
        height: fit-content;
        //align-self: flex-end;
        margin: auto;

        max-height: 80%;
        overflow-y:scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
          }

        

        .fieldGroup {
            display: flex;
        }

        .groupHalf {
            width: 46%;
        }

        .fieldWrapper {

            margin-top: 1em;
            margin-bottom: 1em;

            &Active{
                border-color: $orange;
            }
            .label {
                color: $dark;
                font-size: $size_14;
            }
            .field {
                border-color: $grey;
                border-radius: 5px;
                border-width: 1px;
                
            }

            .scaler {
                display: flex;
                justify-content: center;

                @include mobile-screen-dynamic(365px){
                    transform: scale(0.9);
                }

                @include mobile-screen-dynamic(325px){
                    transform: scale(0.8);
                }

                @include mobile-screen-dynamic(295px){
                    transform: scale(0.7);
                }
            }

            .selector {

                &Open{ 
                    visibility: visible;
                    opacity: 1;
                }
                &Close{ 
                    visibility: hidden;
                    display: none;
                    opacity: 0;
                }

                .margin {
                    margin-top: 2em;
                    margin-bottom: 2em; 
                }

                .wrapper {
                    .qtySelector {
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 5px;
                        .title {
                            font-weight: 100;
                            font-size: $size_14;
                        }
                        .qty {
                            button {
                                margin: 0;
                                padding: 0;
                                border: 0;
                                background: transparent;
                                cursor: pointer;
                            }
                            button:disabled {
                                opacity: .3;
                            }
                            span {
                                display: inline-block;
                                width: 10px;
                                text-align: center;
                                margin: 0 5px;
                            }
                        }
                    }
                    .roomTitle {
                        font-family: $font-fertigo;
                        font-size: $size_12;
                        line-height: $size_20;
                        margin: 10px 0;
                        letter-spacing: 2px;
                        color: $khaki;
                        text-transform: uppercase;
                        border-bottom: 2px solid $khaki;
                    }
                }
            }
        }
    }


}

.inputField {
    margin-bottom: 10px;
    label {
        display: block;
        font-weight: 100;
        font-size: $size_14;
        margin-bottom: 5px;
    }
}

.input {
    border-width: 2px;
    border-style: solid;
    border-color: transparent;
    border-radius: 3px;
    
    padding: 11px 11px 9px;
    font-weight: 100;
    font-size: $size_14;
    line-height: 24px;
    color: $brown;
    background-color: $white;
    width: 100%;
    padding: 11px 11px 9px;

    &.thin {
        border-width: 1px;
        border-color: $grey-light;
    }
}
@include placeholder {
  
}


