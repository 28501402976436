@import '@styles/variables.scss';
@import '@styles/mixins.scss';

.btn {
    background-color: $orange;
    border: none;
    color: $white !important;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border-radius: 4px;
    font-size: $size_12;
    font-family: fertigo-pro, serif;
    font-weight: 500;
    font-style: normal;
    letter-spacing: $fertigo-spacing;
    padding: 16px 28px;
    display: flex;
    justify-content: center;
    cursor: pointer;

    &.wider, &.wider-center {
        width: 100%;
    }
    &.wider-center {
        display: block;
        margin: 0 auto;
    }

    &:hover {
        background-color: $orange-hover;
        color: $white;
    }

    &.eventButton {
        @include mobile-screen-dynamic(346px) {
            font-size: $size_10;
            padding: 12px 21px;
        }
    }
}